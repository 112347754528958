import auth0, { Auth0Error } from 'auth0-js';
import jwtDecode from 'jwt-decode';
import { datadogLogs } from '@datadog/browser-logs';

const oneSecondInMilliseconds = 1000;

interface DecodedToken {
  sub: string;
  'https://qsl.com/groups/': string[];
  'https://qsl.com/groupsSites/': GroupSite[];
  'https://qsl.com/qslinternaluser/': boolean;
  'http://qsl.com/contactId'?: string;
  'http://qsl.com/companyId'?: string;
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress': string;
  'http://qsl.com/contacts'?: Contact[];
}

interface Contact {
  contactId: string;
  companyId: string;
}

interface GroupSite {
  name: string;
  sites: string[];
  jurisdictions: string[]; // TODO Remove when TC3-3838 will be implemented in front-end apps
  siteGroups: string[];
}

const hashAuthSessionData = (accessToken: string, idToken: string, expiresAt: string) => {
  const str = accessToken.concat('', idToken, expiresAt);
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  // Convert to 32bit unsigned integer in base 36 and pad with "0" to ensure length is 7.
  return (hash >>> 0).toString(36).padStart(7, '0');
};

export default class Auth {
  static setSession(accessToken: string, idToken: string, expiresIn: number): string {
    // Set the time that the access token will expire at
    const expiresAt = JSON.stringify(expiresIn * oneSecondInMilliseconds + new Date().getTime());
    localStorage.setItem('access_token', accessToken);
    localStorage.setItem('id_token', idToken);
    localStorage.setItem('expires_at', expiresAt);
    return hashAuthSessionData(accessToken, idToken, expiresAt);
  }

  static isCurrentAuthDataHashValid = (expectedHash: string): boolean => {
    const accessToken = localStorage.getItem('access_token') || '';
    const idToken = localStorage.getItem('id_token') || '';
    const expiresAt = localStorage.getItem('expires_at') || '';
    const hash = hashAuthSessionData(accessToken, idToken, expiresAt);
    return hash === expectedHash;
  };

  static getAccessToken = () => window.localStorage.getItem('access_token') || '';

  static getExpiration(): number {
    const expiresAt = localStorage.getItem('expires_at');
    return +(expiresAt || 0);
  }

  static isLoggedIn = (): boolean => {
    const savedExpireAt = window.localStorage.getItem('expires_at');

    if (!savedExpireAt) return false;
    const expiresAt = JSON.parse(savedExpireAt);
    return new Date().getTime() < expiresAt;
  };

  static getGroupSites = (decodedToken: DecodedToken) => {
    if (!decodedToken || !decodedToken['https://qsl.com/groupsSites/']) return false;
    return decodedToken['https://qsl.com/groupsSites/'].reduce(
      (acc, { name, sites }) => ({ ...acc, [name]: sites }),
      {}
    );
  };

  static getJurisdictions = (decodedToken: DecodedToken) => {
    if (!decodedToken || !decodedToken['https://qsl.com/groupsSites/']) return false;
    return decodedToken['https://qsl.com/groupsSites/'].reduce(
      (acc, { name, jurisdictions }) => (jurisdictions ? { ...acc, [name]: jurisdictions } : acc),
      {}
    );
  };

  static getSiteGroupsByRole = (decodedToken: DecodedToken) => {
    if (!decodedToken || !decodedToken['https://qsl.com/groupsSites/']) return false;
    return decodedToken['https://qsl.com/groupsSites/'].reduce(
      (acc, { name, siteGroups }) => (siteGroups ? { ...acc, [name]: siteGroups } : acc),
      {}
    );
  };

  static decodeToken = () => {
    const userToken = window.localStorage.getItem('id_token');
    return userToken ? (jwtDecode(userToken) as IUser) : null;
  };

  static getUserToken = () => {
    const user = Auth.decodeToken();
    return user || { sub: 'global' };
  };

  static getUser(): IUser | null {
    const user = Auth.decodeToken();
    if (!user) return null;

    user.accessToken = localStorage.getItem('access_token')!;
    const decodedToken = jwtDecode<DecodedToken>(user.accessToken);

    user.groups = decodedToken['https://qsl.com/groups/'];
    user.groupSites = Auth.getGroupSites(decodedToken);
    user.jurisdictions = Auth.getJurisdictions(decodedToken);
    user.isInternal = decodedToken['https://qsl.com/qslinternaluser/'];

    const contact = decodedToken['http://qsl.com/contacts']?.[0];
    user.contactId = decodedToken['http://qsl.com/contactId'] || contact?.contactId;
    user.companyId = decodedToken['http://qsl.com/companyId'] || contact?.companyId;
    user.emailAddress = decodedToken['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
    user.siteGroupsByRole = Auth.getSiteGroupsByRole(decodedToken);

    return user;
  }

  auth0: auth0.WebAuth;

  constructor() {
    this.auth0 = new auth0.WebAuth({
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      clientID: String(process.env.REACT_APP_AUTH0_CLIENTID),
      domain: String(process.env.REACT_APP_AUTH0_CUSTOMDOMAIN),
      redirectUri: window.location.origin + '/callback',
      responseType: 'token id_token',
      scope: 'openid profile',
    });
  }

  login = (): void => this.auth0.authorize();

  logout = (): void => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');

    Object.keys(localStorage)
      .filter(key => key.endsWith('-query'))
      .forEach(key => localStorage.removeItem(key));

    this.auth0.logout({
      returnTo: process.env.REACT_APP_AUTH0_LOGOUT_RETURN_TO,
    });
  };

  handleAuthentication(
    sucessCallback: (sessionHash: string) => void,
    errorCallback: (error: Auth0Error | null) => void
  ): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken && authResult.expiresIn) {
        const sessionHash = Auth.setSession(authResult.accessToken, authResult.idToken, authResult.expiresIn);
        sucessCallback(sessionHash);
      } else {
        errorCallback(err);
      }
    });
  }

  renewTokens(): Promise<{ isSuccess: boolean; error?: string; sessionHash?: string }> {
    return new Promise(resolve => {
      const oldExpiration = localStorage.getItem('expires_at');
      this.auth0.checkSession({}, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          // Ca semble etre pour s'assurer que le token n'a pas deja ete renouvele pas un autre MFE
          if (oldExpiration === localStorage.getItem('expires_at')) {
            const sessionHash = Auth.setSession(authResult.accessToken, authResult.idToken, authResult.expiresIn);
            resolve({ isSuccess: true, sessionHash });
          } else {
            resolve({ isSuccess: false });
          }
        } else if (err) {
          datadogLogs.logger.info(`Could not get a new token [${err.error}]:${err.errorDescription || ''}`);
          resolve({ isSuccess: false, error: err.error });
        }
      });
    });
  }
}
