import { getSignalRHubConnection } from './signalR';

declare let SystemJS: any;
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { BotInfo, BrowserInfo, detect, NodeInfo } from 'detect-browser';
import { createDndContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import {
  addErrorHandler,
  AppError,
  BOOTSTRAPPING,
  getAppStatus,
  LOADING_SOURCE_CODE,
  LOAD_ERROR,
  MOUNTING,
  NOT_BOOTSTRAPPED,
  NOT_LOADED,
  NOT_MOUNTED,
  registerApplication,
  SKIP_BECAUSE_BROKEN,
  start,
  UPDATING,
} from 'single-spa';
import * as workerTimers from 'worker-timers';
import { isProcessEnvFalse, isProcessEnvTrue, setupGlobals } from '@vooban/tc3-frontend-globals';
import Auth from './auth/auth';
import { getDatadogLogsConfiguration, getDatadogRumConfiguration } from './datadogConfig';
import { injectSpritesheet } from './uiHelpers';

const auth = new Auth();

const singleSpaUnavailableStatus = [BOOTSTRAPPING, MOUNTING, UPDATING, LOAD_ERROR];

const singleSpaFatalStatus = [NOT_MOUNTED, NOT_LOADED, NOT_BOOTSTRAPPED, SKIP_BECAUSE_BROKEN];

const singleSpaNotFoundStatus = [LOADING_SOURCE_CODE];

declare global {
  interface Window {
    dataLayer: any[];
  }
}

window.dataLayer = window.dataLayer || [];

const logout = (returnToCurrentUrl = false) => {
  if (document.hidden) return;

  const currentUrl = window.location.href;
  datadogLogs.logger.warn(`Logout was called from ${currentUrl}`);
  if (returnToCurrentUrl) localStorage.setItem('beforeLogoutURL', currentUrl);
  clearTimeout();
  auth.logout();
};

setupGlobals();

window.qsl.tc3.auth.getTokenSilently = async () => {
  const delayBeforeExpiration = getDelayBeforeExpiration();
  const delayBeforeTokenRefresh = getDelayBeforeTokenRefresh();
  let renewedWhen = 0;

  if (delayBeforeExpiration <= 0) {
    renewedWhen = 1;
    // Token expired, renew now and wait for valid token
    await renewAccessToken(false);
  } else if (delayBeforeTokenRefresh <= maximumTokenCheckDelay) {
    renewedWhen = 2;
    // renew but don't wait, current token is still good
    renewAccessToken(false);
  }
  const accessToken = Auth.getAccessToken();

  if (!accessToken) {
    if (renewedWhen === 1) {
      datadogLogs.logger.info('(getTokenSilently) Token expired, renewing now.');
    } else if (renewedWhen === 2) {
      datadogLogs.logger.info('(getTokenSilently) Token will expire soon, renewing now.');
    } else {
      datadogLogs.logger.info('(getTokenSilently) No renewal needed.');
    }
    datadogLogs.logger.error('(getTokenSilently) No token provided.');
  }

  return accessToken;
};

const isGoogleAnalyticsActive = () => !!process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

const shouldDisplayBanner = () => {
  const displayBanner = isProcessEnvTrue('REACT_APP_SHOULD_DISPLAY_BANNER');
  return displayBanner;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function gtag(..._params: any[]) {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

const appendUserIdGoogleAnalyticsTag = (user: IUser | null) => {
  if (user) {
    gtag('config', process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID, {
      userId: user.sub,
      sessionId: user.sid,
      custom_map: { dimension1: 'userId', dimension2: 'sessionId' },
    });
  }
};

const appendGoogleAnalyticsTags = () => {
  const script = document.createElement('script');
  script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID}`;
  script.async = true;
  document.head.appendChild(script);

  gtag('js', new Date());
};

isGoogleAnalyticsActive() && appendGoogleAnalyticsTags();

const initializeDatadogLogs = () => {
  const config = getDatadogLogsConfiguration();
  if (config) {
    datadogLogs.init(config);
  }
};

const initializeDatadogRum = () => {
  const config = getDatadogRumConfiguration();
  if (config) {
    datadogRum.init(config);
  }
};

interface IApp {
  paths: string[];
  entrypoint: string;
}

const tc3OperationalSites = 'operationalSites';
const tc3Invoicing = 'invoicing';
const tc3Flash = 'flash';
const tc3Project = 'project';
const tc3Sales = 'sales';
const tc3Appointment = 'appointment';
const tc3Vessel = 'vessel';
const tc3Inventory = 'inventory';
const tc3Telemetry = 'telemetry';
const tc3Workflow = 'workflow';
const tc3Menu = 'menu';
const tc3Errors = 'errors';

const contextualApps: { [key: string]: IApp } = {
  [tc3OperationalSites]: {
    paths: ['operational-sites'],
    entrypoint: '/tc3-operational-sites/entrypoint.min.js',
  },
  [tc3Invoicing]: { paths: ['invoices'], entrypoint: '/tc3-invoicing/entrypoint.min.js' },
  [tc3Flash]: { paths: ['flash'], entrypoint: '/tc3-flash/entrypoint.min.js' },
  [tc3Project]: {
    paths: ['projects', 'claims', 'purchase-requests', 'purchase-orders', 'purchase-invoices'],
    entrypoint: '/tc3-projects/entrypoint.min.js',
  },
  [tc3Sales]: {
    paths: ['sales', 'admin-settings', 'user-settings', 'external-links'],
    entrypoint: '/tc3-sales/entrypoint.min.js',
  },
  [tc3Appointment]: { paths: ['truck-appointments'], entrypoint: '/tc3-truck-appointments/entrypoint.min.js' },
  [tc3Vessel]: { paths: ['vessels'], entrypoint: '/tc3-vessel/entrypoint.min.js' },
  [tc3Inventory]: { paths: ['client-inventories'], entrypoint: '/tc3-inventory/entrypoint.min.js' },
  [tc3Telemetry]: { paths: ['telemetry'], entrypoint: '/tc3-telemetry/entrypoint.min.js' },
  [tc3Workflow]: { paths: ['workflow'], entrypoint: '/tc3-workflow/entrypoint.min.js' },
};

const tc3AppNames = Object.keys(contextualApps);

document.addEventListener('visibilitychange', function() {
  if (document.visibilityState === 'visible') {
    scheduleRenewal();
  }
});

const dndContext = createDndContext(HTML5Backend);

const channel = new BroadcastChannel('token-synchronizer');

function init() {
  initializeDatadogLogs();
  initializeDatadogRum();

  const hubConnection = getSignalRHubConnection();

  registerApplication(
    tc3Menu,
    () => SystemJS.import('/tc3-menu/entrypoint.min.js'),
    () => true, //Always visible
    { getUser: Auth.getUser, hubConnection, dndContext }
  );

  tc3AppNames.forEach(appName => {
    const { entrypoint } = contextualApps[appName];
    registerApplication(
      appName,
      () => SystemJS.import(entrypoint),
      () => handleApplicationActiveState(appName),
      {
        getUser: Auth.getUser,
        hubConnection,
        dndContext,
      }
    );
  });

  registerApplication(
    'not-found',
    () => import('./notFoundPage'),
    () => window.location.pathname === '/404'
  );
  registerApplication(
    'unavailable',
    () => import('./unavailablePage'),
    () => window.location.pathname === '/503'
  );
  registerApplication(
    'internal-error',
    () => import('./internalErrorPage'),
    () => window.location.pathname === '/500'
  );

  addErrorHandler((err: AppError) => {
    console.log(err);
    if (err.appOrParcelName === tc3Menu) return;

    const status = getAppStatus(err.appOrParcelName) || '';
    console.warn(`${err.appOrParcelName} app has status ${status}`);
    if (singleSpaNotFoundStatus.includes(status)) return window.location.replace('/404');
    if (singleSpaFatalStatus.includes(status)) return window.location.replace('/500');
    if (singleSpaUnavailableStatus.includes(status)) return window.location.replace('/503');
  });

  start();

  if (shouldDisplayBanner()) {
    const header = document.getElementById('header-env');
    if (header !== null) {
      header.style.display = 'flex';
      header.textContent = process.env.REACT_APP_BANNER_VALUE || '';
    }
  }

  if (Auth.isLoggedIn()) {
    scheduleRenewal();
    isGoogleAnalyticsActive() && appendUserIdGoogleAnalyticsTag(Auth.getUser());
  }
}

let tokenRenewalTimeoutId: number | null;

let currentRenewalPromise: Promise<RenewAccessTokenResults> | null = null;

type RenewAccessTokenResults = 'SUCCESS' | 'LOGIN_REQUIRED';

const renewAccessToken = async (scheduleNextRenewal = true): Promise<RenewAccessTokenResults> => {
  // If there is a promise, then a renewal is in progress from another MFE or tab.
  // Return promise so every apps wait on the renewal to be done.
  if (currentRenewalPromise) {
    console.info('Token renewal already in progress.');
    return currentRenewalPromise;
  }

  currentRenewalPromise = auth.renewTokens().then(({ isSuccess, error, sessionHash }) => {
    if (isSuccess) {
      // When warning other tabs, send sessionHash so they can validate they have the tokens in their localStorage.
      channel.postMessage({ name: 'token-synchronizer:token-renewal-completed', sessionHash });
      dispatchSessionRenewedToSingleSpa();
    }

    // If renewal fails for other reasons than login is required, log it, not supposed to happen
    if (error && error !== 'login_required') {
      datadogLogs.logger.error(`Error while renewing token: ${error}`);
    }

    // If tab is hidden, don't bother reacting to a login_required, the first tab to show should handle it
    if (error === 'login_required' && document.hidden) {
      datadogLogs.logger.info('login_required while document is hidden.');
      return 'LOGIN_REQUIRED';
    }
    // Login is required and tab is the one showing, logout
    else if (error === 'login_required' && !document.hidden) {
      datadogLogs.logger.info('login_required while document is visible, logging out.');
      localStorage.setItem('beforeLogoutURL', window.location.href);
      logout();
    } else if (scheduleNextRenewal) {
      scheduleRenewal(false);
    }

    // At this point, the renewal is done, we can reset the promise to be ready for the next renewal.
    currentRenewalPromise = null;
    return 'SUCCESS';
  });

  // Warn other tabs that a renewal is in progress.
  channel.postMessage({ name: 'token-synchronizer:token-renewal-in-progress' });
  return currentRenewalPromise;
};

const dispatchSessionRenewedToSingleSpa = () => {
  console.log('single-spa:session-renewed');
  window.dispatchEvent(new CustomEvent('single-spa:session-renewed', { detail: Auth.getUser() }));
};

/* ------------- Listening to other tabs ----------------- */

let externalTokenRenewed:
  | ((value: RenewAccessTokenResults | PromiseLike<RenewAccessTokenResults>) => void)
  | null = null;

// Compare the sessionHash from another tab with the current one in localStorage.
// If they are the same, then the new tokens are in localStorage.
const waitForAuthDataToBeInLocalStorage = async (sessionHash: string) => {
  for (let retryCount = 0; !Auth.isCurrentAuthDataHashValid(sessionHash) && retryCount <= 9; retryCount++) {
    datadogLogs.logger.warn('Session hash is not valid, waiting for the current one to be set localStorage.');
    await new Promise(resolve => setTimeout(() => resolve(null), 50));
  }
  if (!Auth.isCurrentAuthDataHashValid(sessionHash)) {
    datadogLogs.logger.error('Session hash was never valid in localStorage, logging out.');
    logout();
  }
};

channel.addEventListener('message', async (e: { data: { name: string; sessionHash?: string } }) => {
  if (e.data.name === 'token-synchronizer:token-renewal-in-progress') {
    // If a renewal is in progress in another tab, we need to wait for it to be done
    // We reuse the promise as if it was happening in our tab
    currentRenewalPromise = new Promise(resolve => {
      externalTokenRenewed = resolve;
    });
    console.log('Token renewal has started in another tab.');
  } else if (e.data.name === 'token-synchronizer:token-renewal-completed') {
    console.log('Token renewal has completed in another tab.');
    // Wait for token data to be in our tab's localStorage
    await waitForAuthDataToBeInLocalStorage(e.data.sessionHash!);
    // should always be true, but don't crash for no reason
    if (externalTokenRenewed) externalTokenRenewed('SUCCESS');
    // Reset the promise and the callback plumbing, token update is done
    externalTokenRenewed = null;
    currentRenewalPromise = null;
    // Warning other apps in same tab that the session has been renewed
    dispatchSessionRenewedToSingleSpa();
    // Schedule the next renewal
    scheduleRenewal(false);
  }
});

/* ---------------------------------------------------- */

const maximumTokenCheckDelay = 10_000;

const refreshTimeBeforeExpiration = 60_000;

const getDelayBeforeExpiration = () => {
  const expiration = Auth.getExpiration();
  return expiration - Date.now();
};

const getDelayBeforeTokenRefresh = () => {
  const delayBeforeExpiration = getDelayBeforeExpiration();
  const randomMilliseconds = Math.random() * 30;
  return delayBeforeExpiration - refreshTimeBeforeExpiration - randomMilliseconds;
};

const setTimeout = (func: () => void, delay: number) => {
  if (tokenRenewalTimeoutId) {
    workerTimers.clearTimeout(tokenRenewalTimeoutId);
  }

  tokenRenewalTimeoutId = workerTimers.setTimeout(func, delay);
};

const clearTimeout = () => {
  if (tokenRenewalTimeoutId) {
    try {
      workerTimers.clearTimeout(tokenRenewalTimeoutId);
    } catch (error) {
      // Contrairement au clearTimeout natif, workerTimers.clearTimeout lance une exception si le timer avec l'id pass� n'existe pas.
    }
  }

  tokenRenewalTimeoutId = null;
};

const scheduleRenewal = (checkNow = true) => {
  const delayBeforeTokenRefresh = getDelayBeforeTokenRefresh();

  if (checkNow && delayBeforeTokenRefresh <= 0) {
    renewAccessToken();
    return;
  }

  if (delayBeforeTokenRefresh <= maximumTokenCheckDelay && delayBeforeTokenRefresh > 0) {
    setTimeout(renewAccessToken, delayBeforeTokenRefresh);
  } else {
    setTimeout(scheduleRenewal, maximumTokenCheckDelay);
  }
};

const locationStartsWithAppPath = (name: string) => {
  // This expression matches /{appName} or /{appname}/*
  const regex = new RegExp('^/' + name + '($|/.*)');
  return !!window.location.pathname.match(regex);
};

const isValidPath = () => {
  return (
    Object.keys(contextualApps).some(appName =>
      contextualApps[appName].paths.some(path => locationStartsWithAppPath(path))
    ) || ['/', '/404', '/403', '/503'].some(path => window.location.pathname === path)
  );
};

const handleApplicationActiveState = (appName: string) => {
  return contextualApps[appName].paths.some(path => locationStartsWithAppPath(path));
};

const callbackAction = () => {
  auth.handleAuthentication(
    sessionHash => {
      const savedUrlToRedirect = localStorage.getItem('beforeLogoutURL') || localStorage.getItem('requestedURL');
      localStorage.removeItem('requestedURL');
      localStorage.removeItem('beforeLogoutURL');
      const urlToRedirect = savedUrlToRedirect || '/';
      window.location.replace(urlToRedirect);
      channel.postMessage({ name: 'token-synchronizer:token-renewal-completed', sessionHash });
    },
    err => {
      console.log(`Could not get a new token [${err?.error}]:${err?.errorDescription || ''}`);
      return window.location.replace(
        `login-error.html${err?.errorDescription ? `?error=${err?.errorDescription}` : ''}`
      );
    }
  );
};

const welcomeAction = () => {
  window.location.replace('welcome.html');
};

const isBrowserSupported = (browser: BrowserInfo | BotInfo | NodeInfo) => {
  if (isProcessEnvFalse('REACT_APP_ENABLE_RESTRICT_BROWSERS')) return true;

  switch (browser.name) {
    case 'chrome':
    case 'edge-chromium':
    case 'crios':
      return true;
    default:
      return false;
  }
};

const getDefaultAppBasedOnRole = (user: IUser) => {
  const isInternalUser = user.isInternal && isProcessEnvTrue('REACT_APP_FEATURE_WORKFLOW');
  // user with more than a group will fallback on default
  const [role] = user.groups.length > 1 ? [] : user.groups;
  switch (role) {
    case 'inventoryreader':
      return tc3Inventory;
    case 'inventoryclerk':
      return tc3Inventory;
    case 'maritimeagent':
      return tc3Project;
    case 'gatekeeper':
      return tc3Appointment;
    default:
      return isInternalUser ? tc3Workflow : tc3Appointment;
  }
};

const getDefaultPage = () => {
  const user = Auth.getUser();
  if (!user) return '';

  const baseApp = getDefaultAppBasedOnRole(user);
  return contextualApps[baseApp].paths[0];
};

window.addEventListener('load', async () => {
  const browser = detect();
  const user = Auth.getUser();

  injectSpritesheet('/static/icons.svg').catch();

  registerApplication(
    'unsupported-browser',
    () => import('./unsupportedBrowserPage'),
    () => window.location.pathname === '/unsupported-browser'
  );
  registerApplication(
    tc3Errors,
    () => import('./unauthorizedPage'),
    () => window.location.pathname === '/403'
  );

  switch (window.location.pathname) {
    case '/401':
    case '/logout':
      return logout();
    case '/callback':
      return callbackAction();
    case '/welcome':
      return welcomeAction();
    case '/unsupported-browser':
    case '/403':
      return start();
    default:
      if (browser && !isBrowserSupported(browser)) {
        window.location.replace('/unsupported-browser');
        console.log(`Browser:`, browser.name, browser.version, '| OS:', browser.os);
      } else if (!Auth.isLoggedIn()) {
        localStorage.setItem('requestedURL', window.location.href);
        auth.login();
      } else if (!user?.groups?.length) {
        window.location.replace('/403');
      } else if (!isValidPath()) {
        window.location.replace('/404');
      } else if (window.location.pathname === '/') {
        window.location.replace(getDefaultPage());
      } else {
        init();
      }
      break;
  }
});

window.addEventListener('single-spa:request-login', () => {
  localStorage.setItem('requestedURL', window.location.href);
  auth.login();
});

window.addEventListener('single-spa:request-logout', () => logout(true));
